import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/Linotype-NHaasGroteskTXPro-75Bd.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"./fonts/Linotype-NHaasGroteskTXPro-55Rg.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./fonts/Linotype-NHaasGroteskTXPro-65Md.woff2\",\"weight\":\"500\",\"style\":\"normal\"}],\"variable\":\"--font-body\"}],\"variableName\":\"bodyFont\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/Linotype-NHaasGroteskDSPro-75Bd.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--font-heading\"}],\"variableName\":\"headingFont\"}");
;
import(/* webpackMode: "eager" */ "/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/node_modules/sonner/dist/index.mjs");
